import 'magnific-popup';

jQuery( document ).ready(function($) {

	$('.mfp-inline').magnificPopup();
	$('.mfp-image').magnificPopup({
		type: 'image'
	});

    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');
        e.preventDefault();
        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    // Instagram
	$.ajax({
		method: 'GET',
		url: 'https://www.juicer.io/feeds/dreek',
		dataType: 'json',
		success: ( data ) => {
			if ( data ) {
				let i = 0;
				let html = '';
				for ( let item of data.posts.items ) {
					html += '<div>';
					html += '<a href="' + item.full_url + '" target="_blank">';
					html += '<img src="' + item.image + '" alt="' + item.id + '" />';
					html += '</a>';
					html += '</div>';
					i ++;
					if ( i >= 8 ) {
						break;
					}
				}

				$( '#feed' ).html( html );
			}
		},
		error: () => {
			console.log( 'An error has occurred fetching IG photos.' );
		}
	});
});